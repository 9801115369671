export { matchers } from './client-matchers.js';

export const components = [
	() => import("../../src/routes/__layout@root.svelte"),
	() => import("../runtime/components/error.svelte"),
	() => import("../../src/routes/__layout-root.svelte"),
	() => import("../../src/routes/archive/__error.svelte"),
	() => import("../../src/routes/studio-sh/__layout@root.svelte"),
	() => import("../../src/routes/submit/__layout.svelte"),
	() => import("../../src/routes/[slug].svelte"),
	() => import("../../src/routes/about.svelte"),
	() => import("../../src/routes/advertise.svelte"),
	() => import("../../src/routes/archive/[slug].svelte"),
	() => import("../../src/routes/archive/index.svelte"),
	() => import("../../src/routes/calendar/index.svelte"),
	() => import("../../src/routes/category/[category]/index.svelte"),
	() => import("../../src/routes/imprint.svelte"),
	() => import("../../src/routes/index.svelte"),
	() => import("../../src/routes/post/ContentBlocks.svelte"),
	() => import("../../src/routes/post/[post].svelte"),
	() => import("../../src/routes/privacy-policy.svelte"),
	() => import("../../src/routes/search/index.svelte"),
	() => import("../../src/routes/showcase/blocks.svelte"),
	() => import("../../src/routes/studio-sh/about.svelte"),
	() => import("../../src/routes/studio-sh/index.svelte"),
	() => import("../../src/routes/studio-sh/projects/[project].svelte"),
	() => import("../../src/routes/studio-sh/projects/index.svelte"),
	() => import("../../src/routes/submission/ImageWithCaption.svelte"),
	() => import("../../src/routes/submission/[post].svelte"),
	() => import("../../src/routes/submit/event.svelte"),
	() => import("../../src/routes/submit/exhibition.svelte"),
	() => import("../../src/routes/submit/index.svelte"),
	() => import("../../src/routes/submit/success.svelte"),
	() => import("../../src/routes/trigger-error.svelte")
];

export const dictionary = {
	"": [[2, 0, 14], [1, 1], 1],
	"about": [[2, 0, 7], [1, 1]],
	"advertise": [[2, 0, 8], [1, 1]],
	"archive": [[2, 0, , 10], [1, 1, 3]],
	"calendar": [[2, 0, 11], [1, 1], 1],
	"imprint": [[2, 0, 13], [1, 1]],
	"privacy-policy": [[2, 0, 17], [1, 1]],
	"search": [[2, 0, 18], [1, 1], 1],
	"studio-sh": [[2, 4, 21], [1]],
	"submit": [[2, 0, 5, 28], [1, 1]],
	"trigger-error": [[2, 0, 30], [1, 1]],
	"post/ContentBlocks": [[2, 0, 15], [1, 1]],
	"showcase/blocks": [[2, 0, 19], [1, 1]],
	"studio-sh/about": [[2, 4, 20], [1]],
	"studio-sh/projects": [[2, 4, 23], [1]],
	"submission/ImageWithCaption": [[2, 0, 24], [1, 1]],
	"submit/event": [[2, 0, 5, 26], [1, 1]],
	"submit/exhibition": [[2, 0, 5, 27], [1, 1]],
	"submit/success": [[2, 0, 5, 29], [1, 1]],
	"studio-sh/projects/[project]": [[2, 4, 22], [1]],
	"archive/[slug]": [[2, 0, , 9], [1, 1, 3], 1],
	"category/[category]": [[2, 0, 12], [1, 1], 1],
	"post/[post]": [[2, 0, 16], [1, 1], 1],
	"submission/[post]": [[2, 0, 25], [1, 1], 1],
	"[slug]": [[2, 0, 6], [1, 1]]
};